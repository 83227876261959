import React from 'react';
import { Link } from 'react-router-dom';
import { FacebookProvider, LoginButton } from 'react-facebook';
import ButtonLink from '../components/ButtonLink';

import { authFB, FB_SIGNUP_SUCCESSFUL } from 'reducers/usersActions';
import { useDispatch } from 'react-redux';

const WelcomeScreen = () => {
    const element = document.querySelector('meta[name="fb:app_id"]');
    const fbAppID = element && element.getAttribute('content');

    const dispatch = useDispatch();
    const handleComplete = data => {
      authFB(data).then(response => dispatch({ type: FB_SIGNUP_SUCCESSFUL, payload: response }))
    }

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center p-5 registration h-100"
            style={{ minHeight: '100vh' }}>
            <h1 style={{ marginBottom: 'auto', paddingTop: '25vh', height: '50vh'}}>IGNITE HOUSE</h1>
            {fbAppID && (
                <FacebookProvider appId={fbAppID}>
                    {/* <LoginButton
                        className="btn btn-primary rounded-0 btn-block mt-5 btn-lg"
                        scope="email"
                        onCompleted={handleComplete}
                        onError={() => {}}>
                        <span>Continue with Facebook</span>
                    </LoginButton> */}
                </FacebookProvider>
            )}
            <ButtonLink to="/sign-up" block large className="mt-3 mb-4">
                Register with email
            </ButtonLink>
            <p className="mt-4 mb-0 text-center">
                I already have an account. <Link to="/login">Log in</Link>
            </p>
            <p
                className="mt-3 mb-0 registration-terms text-center"
                style={{ fontSize: '0.9em' }}>
                By logging in, you agree to our{' '}
                <a href="https://www.ignitegifts.com/terms" target="_blank">Terms of Use</a> and{' '}
                <a href="https://www.ignitegifts.com/privacy" target="_blank">Data Privacy Policy</a>.
            </p>
        </div>
    );
};

export default WelcomeScreen;
