import React from 'react';
import { QuestionHeader } from './GrowthLabQuestionScreen';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import ScheduleCall from './GiveMeGrowth/ScheduleCall';
import GiveMeGrowthForm from './GiveMeGrowth/Form';
import FeedbackForm from './PayItForward/Feedback';
import InviteForm from './PayItForward/Invite';

const PayItForwardScreen = ({ goToItineraryGroup, currentArea, baseUrl }) => {
    const rootPath = `/${currentArea.slug}`;
    return (
        <div className="w-100">
            <QuestionHeader
                title={currentArea.title}
                letter={currentArea.letter}
            />
            <BrowserRouter basename={baseUrl}>
                <Switch>
                    <Route path={rootPath} exact>
                        <FeedbackForm
                            eventId={currentArea.eventId}
                            goToItineraryGroup={goToItineraryGroup}
                            next={`${rootPath}/invite`}
                        />
                    </Route>
                    <Route path={`${rootPath}/invite`}>
                        <InviteForm goToItineraryGroup={goToItineraryGroup} />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default PayItForwardScreen;
