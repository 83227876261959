import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from './Button';

const ScreenHeader = ({ pretitle, title, children, goHome }) => {
    const history = useHistory();

    const goBack = e => {
        e.preventDefault();
        if (goHome) {
            goHome();
        } else {
            history.goBack();
        }
    };

    return (
        <header className="header-body border-0 mb-3">
            <div className="row align-items-end">
                <div className="col">
                    <Button
                        transparent
                        large
                        className="border-0 p-0 mb-3"
                        onClick={goBack}>
                        <i className="fe fe-arrow-left" />
                    </Button>
                    {pretitle && (
                        <h6 className="header-pretitle">{pretitle}</h6>
                    )}

                    {title && <h1 className="header-title">{title}</h1>}
                    {children}
                </div>
            </div>
        </header>
    );
};

export default ScreenHeader;
