import React, { Fragment, useEffect, useState } from 'react';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import { useDispatch } from 'react-redux';
import { getPayItForward, giveMeGrowth } from '../../reducers/itineraryActions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Radio } from '../../components/Inputs';
import { Select } from '../../components/QuestionInput';
import setResponseErrors from '../../utils/setResponseErrors';
import Loader from '../../components/Loader';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';

const FIRST_PART_CHOICES = [
    { label: '100+ Pax', value: '100' },
    { label: 'Up to 100 Pax', value: '99' },
    { label: 'Up to 50 Pax', value: '50' },
    { label: 'Up to 20 Pax', value: '20' },
    { label: 'Up to 10 Pax', value: '10' },
    { label: 'Myself', value: '1' },
];

const SECOND_PART_CHOICES = [
    { body: 'Innovation', value: 'INN' } /*INN*/,
    { body: 'Customer-Centricity', value: 'CHI' },
    { body: 'Collaboration', value: 'COL' },
    { body: 'Purpose-Driven Teams', value: 'PEA' },
    { body: 'All 4 Growth Streams', value: '4GS' },
];

const GiveMeGrowthForm = ({ eventId, goToItineraryGroup }) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState(null);

    useEffect(() => {
        dispatch(getPayItForward(eventId)).then(
            res => {
                setInitialValues({
                    number_of_people: res.number_of_people || '',
                    learn_more: res.learn_more || [],
                });
            },
            err => {
                setInitialValues({
                    number_of_people: '',
                    learn_more: [],
                });
            },
        );
    }, []);

    if (!initialValues) {
        return (
            <QuestionBody>
                <Loader fullscreen />
            </QuestionBody>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                dispatch(giveMeGrowth(eventId, values)).then(
                    res => {
                        setSubmitting(false);
                        goToItineraryGroup();
                    },
                    err => {
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {({ isSubmitting }) => (
                <Form>
                    <QuestionBody>
                        <Field
                            label="I want Growth for (select one):"
                            name="number_of_people"
                            component={Radio}
                            disabled={isSubmitting}
                            options={FIRST_PART_CHOICES}
                        />
                        <ErrorMessage
                            name="number_of_people"
                            component={ErrorMessageContainer}
                        />
                        <Field
                            label="I want Growth for (select one):"
                            name="learn_more"
                            max={2}
                            component={Select}
                            disabled={isSubmitting}
                            options={SECOND_PART_CHOICES}
                        />
                        <ErrorMessage
                            name="learn_more"
                            component={ErrorMessageContainer}
                        />
                    </QuestionBody>
                    <QuestionFooter
                        goHome={goToItineraryGroup}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default GiveMeGrowthForm;
