import {
    SIGN_UP_SUCCESSFUL,
    LOGIN_SUCCESSFUL,
    FB_SIGNUP_SUCCESSFUL,
    LOGOUT,
    UPDATE_USER_SUCCESSFUL,
} from './usersActions';

const initialState = {
    user: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP_SUCCESSFUL:
        case LOGIN_SUCCESSFUL:
        case FB_SIGNUP_SUCCESSFUL:
            return {
                ...state,
                user: action.payload.user,
            };
        case LOGOUT:
            return {
                ...state,
                user: {},
            };
        case UPDATE_USER_SUCCESSFUL:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export default reducer;
