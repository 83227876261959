import React, { useState } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { OpenText } from '../../components/QuestionInput';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import Button from '../../components/Button';

const Declarations = ({ goToItineraryGroup }) => {
    const [max, setMax] = useState(3);
    return (
        <Formik
            onSubmit={values => {
                goToItineraryGroup();
            }}
            initialValues={{
                declarations: [''],
            }}>
            {({ values }) => (
                <Form>
                    <QuestionBody>
                        <p className="font-weight-bold mb-4">
                            Give the gift that keeps giving. Who comes to mind
                            among friends and family who I'd like to experience
                            Ignite?
                        </p>
                        <FieldArray
                            name="declarations"
                            render={arrayHelpers => (
                                <div className="d-flex flex-column align-items-end">
                                    {values.declarations &&
                                    values.declarations.length > 0
                                        ? values.declarations.map((_, i) => (
                                              <div
                                                  key={i}
                                                  className="d-flex align-items-center mb-3 w-100">
                                                  <Field
                                                      labelClassName="font-weight-bold"
                                                      name={`declarations.${i}`}
                                                      component={OpenText}
                                                      label="I commit to these Personal Behaviors:"
                                                  />
                                                  {/* <Button
                                                      className="mb-5"
                                                      transparent
                                                      onClick={() =>
                                                          arrayHelpers.remove(i)
                                                      }>
                                                      <i className="fe fe-x" />
                                                  </Button> */}
                                              </div>
                                          ))
                                        : null}
                                    {values.declarations.length < max && (
                                        <Button
                                            transparent
                                            onClick={() =>
                                                arrayHelpers.push('')
                                            }>
                                            Add More{' '}
                                            <i className="fe fe-plus" />
                                        </Button>
                                    )}
                                </div>
                            )}
                        />
                    </QuestionBody>
                    <QuestionFooter goHome={goToItineraryGroup} />
                </Form>
            )}
        </Formik>
    );
};

export default Declarations;
