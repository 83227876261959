import React, { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { Range, getTrackBackground } from 'react-range';

export const OpenText = ({
    field,
    className = '',
    id = '',
    label = '',
    labelClassName = '',
    disabled,
    rows = 1,
    noMargin,
}) => {
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        onBlur(e);
    };

    return (
        <div className={`QuestionInput w-100 ${noMargin ? '' : 'mb-5'}`}>
            {label && (
                <label
                    className={labelClassName}
                    htmlFor={id || name}
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            )}
            <TextareaAutosize
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                placeholder="Write here"
                disabled={disabled}
                rows={rows}
            />
        </div>
    );
};

export const Select = ({
    field,
    className = '',
    label = '',
    max = Infinity,
    options,
    disabled,
}) => {
    const { name, value, ...others } = field;

    const reachedMax = field.value.length >= max;

    return (
        <div className="QuestionInput mb-5">
            <label
                className="font-weight-bold"
                dangerouslySetInnerHTML={{ __html: label }}
            />
            {options.map((option, i) => {
                const isSelected = field.value.includes(option.value);
                return (
                    <label
                        className="QuestionInput--option mt-3"
                        key={i}
                        htmlFor={`${name}-${i}`}>
                        <input
                            key={i}
                            type="checkbox"
                            id={`${name}-${i}`}
                            name={name}
                            value={option.value}
                            placeholder="Write here"
                            disabled={disabled || (reachedMax && !isSelected)}
                            {...others}
                            style={{ display: 'none' }}
                        />
                        {isSelected ? (
                            <i
                                className="fe fe-check-square"
                                style={{
                                    color: '#276ef1',
                                    fontSize: '1.125rem',
                                }}
                            />
                        ) : (
                            <i
                                className="fe fe-square"
                                style={{
                                    color: '#bfbfc1',
                                    fontSize: '1.125rem',
                                }}
                            />
                        )}
                        <span className="ml-3">{option.body}</span>
                    </label>
                );
            })}
        </div>
    );
};

export const Slider = ({
    field,
    form,
    className = '',
    label = '',
    max = Infinity,
    options,
    disabled,
    slider_length,
    slider_left_label,
    slider_right_label,
}) => {
    return (
        <div className="QuestionInput mb-5">
            <label
                className="mb-3"
                dangerouslySetInnerHTML={{ __html: label }}
            />
            <Range
                disabled={disabled}
                onChange={v => form.setFieldValue(field.name, v)}
                onFinalChange={v => form.setFieldValue(field.name, v)}
                min={0}
                max={slider_length}
                step={1}
                values={field.value}
                renderTrack={({ props, children }) => (
                    <div
                        className="mb-3"
                        {...props}
                        style={{
                            ...props.style,
                            height: '4px',
                            width: '100%',
                            background: getTrackBackground({
                                values: field.value,
                                colors: ['#000', '#bfbfc1'],
                                min: 0,
                                max: slider_length,
                            }),
                        }}>
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        className="d-flex align-items-center justify-content-center my-auto"
                        {...props}
                        style={{
                            ...props.style,
                            borderRadius: '50%',
                            width: '1rem',
                            height: '1rem',
                            overflow: 'hidden',
                            backgroundColor: '#000',
                        }}></div>
                )}
            />
            <div className="d-flex align-items-center justify-content-between">
                <span>{slider_left_label}</span>
                <span>{slider_right_label}</span>
            </div>
        </div>
    );
};
