import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../reducers/modalActions';

export const ModalHeader = ({ title }) => (
    <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
);

export const ModalBody = ({ children }) => (
    <div className="modal-body">{children}</div>
);

export const ModalFooter = ({ children }) => (
    <div className="modal-footer">{children}</div>
);

const Modal = ({ title, centered, children, afterCloseCallBack }) => {
    const node = useRef();
    const [isMounted, setIsMounted] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();

    const handleClickOutside = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        closeModal();
    };

    const closeModal = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsMounted(false);
            dispatch(hideModal());
            afterCloseCallBack && afterCloseCallBack();
        }, 300);
    };

    useEffect(() => {
        setIsMounted(true);
        setTimeout(() => {
            setIsVisible(true);
        }, 10);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Fragment>
            <div
                className={`modal fade ${isVisible ? 'show' : ''}`}
                tabIndex="-1"
                role="dialog"
                style={isMounted ? { display: 'block' } : {}}>
                <div
                    className={`modal-dialog ${
                        centered ? 'modal-dialog-centered' : ''
                    }`}
                    role="document">
                    <div className="modal-content" ref={node}>
                        {title && <ModalHeader title={title} />}
                        {children}
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade ${isVisible ? 'show' : ''}`} />
        </Fragment>
    );
};

export default Modal;
