export const PRACTICE_QUESTION = `Select the statement that best describe your organization's current practices and behaviors. Note: you are selecting statements that best describe your organization, not yourself.`;

export const PRACTICE_OPTIONS = [
    {
        label:
            'We do not hear about Collaboration, speak about it, or act on it in any significant way.',
        value: 0,
    },
    {
        label:
            'We talk about Collaboration a lot and preach it as very important to our success.',
        value: 1,
    },
    {
        label:
            'We create materials and campaigns to keep Collaboration top of mind.',
        value: 2,
    },
    {
        label:
            'We send our people to workshops, conferences, seminars and other programs designed to inspire greater action around Collaboration.',
        value: 3,
    },
    {
        label:
            'We have empowered specialized teams to lead and deliver collaboration initiatives.',
        value: 4,
    },
    {
        label:
            'We actively pursue behaviors and practices that help us to work across silos so that we can collaborate with a diversity of parties across teams, departments, and business units and even with parties outside of our company and industry.',
        value: 5,
    },
];

export const FREQUENCY_QUESTION =
    'How often does your organization pursue the practices and behaviors that you identified above?';

export const FREQUENCY_OPTIONS = [
    { label: '2x yearly', value: 1 },
    { label: 'Rarely', value: 2 },
    { label: 'Quarterly', value: 3 },
    { label: 'Monthly', value: 4 },
    { label: 'Weekly', value: 5 },
];

export const WORKFORCE_QUESTION =
    'What % of your workforce is held accountable for practices and behaviors that you identified above?';

export const WORKFORCE_OPTIONS = [
    { label: '10%', value: 1 },
    { label: '25%', value: 2 },
    { label: '50%', value: 3 },
    { label: '70%', value: 4 },
    { label: '90%', value: 5 },
];
