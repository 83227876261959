import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ScreenHeader from '../components/ScreenHeader';
import * as yup from 'yup';
import { Input } from '../components/Inputs';
import Button from '../components/Button';
import { forgotPassword } from '../reducers/usersActions';
import ErrorMessageContainer from '../components/ErrorMessageContainer';
import { useDispatch } from 'react-redux';
import { MODAL_TYPES, showModal } from '../reducers/modalActions';
import setResponseErrors from '../utils/setResponseErrors';

const ForgotPasswordSchema = yup.object().shape({
    email: yup
        .string()
        .email('Must input a valid email address.')
        .required('Email is required.'),
});

const ForgotPasswordScreen = () => {
    const dispatch = useDispatch();
    return (
        <div className="py-1 px-4" style={{ height: '100vh' }}>
            <ScreenHeader title="Reset Password">
                <p className="mt-3 mb-0">
                    Please enter the email address you used to register with us
                    and we’ll send you the instructions to reset your password.
                </p>
            </ScreenHeader>

            <Formik
                validationSchema={ForgotPasswordSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    setSubmitting(true);
                    forgotPassword(values).then(
                        () => {
                            setSubmitting(false);
                            dispatch(showModal(MODAL_TYPES.RESET_PW_SUCCESS));
                        },
                        err => {
                            setSubmitting(false);
                            setResponseErrors(err, setFieldError);
                        },
                    );
                }}
                initialValues={{
                    email: '',
                }}>
                {({ isSubmitting }) => (
                    <Form
                        className="d-flex flex-column pb-3"
                        style={{ minHeight: 'calc(100% - 200px)' }}>
                        <Field
                            name="email"
                            label="Email Address"
                            type="email"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="email"
                            component={ErrorMessageContainer}
                        />

                        <Button
                            block
                            large
                            type="submit"
                            className="mt-auto"
                            disabled={isSubmitting}>
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            <span> Send</span>
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPasswordScreen;
