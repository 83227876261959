import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ItineraryGroup = ({ name, slug, image }) => {
    return (
        <li className="d-block mb-3">
            <Link
                to={`/groups/${slug}`}
                className="d-flex align-items-end text-body"
                style={{
                    minHeight: '9rem',
                    height: '23vh',
                    paddingLeft: '5%',
                    paddingBottom: '5%',
                    borderRadius: '0.375rem',
                    position: 'relative',
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom right',
                }}>
                <span
                    style={{
                        fontWeight: 'bold',
                    }}>
                    {name}
                </span>
            </Link>
        </li>
    );
};

const ItineraryGroups = () => {
    const groups = useSelector(s => s.itinerary.groups);
    return (
        <div className="px-5">
            <h2>Choose your Itinerary</h2>
            <ul className="list-unstyled">
                {groups.map(group => (
                    <ItineraryGroup
                        key={group.id}
                        name={group.name}
                        slug={group.slug}
                        image={group.image}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ItineraryGroups;
