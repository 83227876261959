import React, { Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PublicScreen from '../screens/PublicScreen';
import { useSelector, useDispatch } from 'react-redux';
import PrivateScreen from '../screens/PrivateScreen';
import Modal from '../components/Modal';
import { MODAL_TYPES, hideModal } from '../reducers/modalActions';
import Button from '../components/Button';

const App = () => {
    const isAuthenticated = useSelector(s => s.app.auth_token);
    const modal = useSelector(s => s.modal);
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <Fragment>
            <div className="container px-0" style={{ maxWidth: 540 }}>
                {!isAuthenticated ? <PublicScreen /> : <PrivateScreen />}
            </div>
            {modal.show && modal.type === MODAL_TYPES.RESET_PW_SUCCESS ? (
                <Modal
                    centered
                    afterCloseCallBack={() => {
                        history.push('/login');
                    }}>
                    <div className="p-5 text-center">
                        <i
                            className="fe fe-check-circle text-success"
                            style={{ fontSize: '3rem' }}
                        />
                        <h2 className="mb-3">Password Reset</h2>
                        <p className="mt-0">
                            We successfully reset your password. Please check
                            your email for the instructions.
                        </p>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.LOCK_MODAL ? (
                <Modal centered>
                    <div className="p-5 text-center">
                        <i
                            className="fe fe-user-x text-warning"
                            style={{ fontSize: '3rem' }}
                        />
                        <h2 className="mb-3">{modal.lockDetails.title}</h2>
                        <p className="mt-0">{modal.lockDetails.body}</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <Button
                                light
                                className="w-100 mr-3"
                                onClick={() => {
                                    dispatch(hideModal());
                                }}>
                                Close
                            </Button>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="btn btn-primary rounded-0 w-100">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default App;
