import React, { Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import { Radio } from '../../components/Inputs';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';
import { useHistory } from 'react-router-dom';
import {
    PRACTICE_QUESTION,
    PRACTICE_OPTIONS,
    FREQUENCY_OPTIONS,
    FREQUENCY_QUESTION,
    WORKFORCE_QUESTION,
    WORKFORCE_OPTIONS,
} from './GQOptions';
import { useDispatch } from 'react-redux';
import setResponseErrors from '../../utils/setResponseErrors';
import * as yup from 'yup';
import { postGrowthQuotient } from '../../reducers/itineraryActions';

const QuestionSchema = yup.object().shape({
    practice: yup.number().required('Select at least one'),
    frequency: yup.number().when('practice', {
        is: val => val > 0,
        then: yup
            .number()
            .min(1, 'This field is required')
            .required('This field is required'),
        otherwise: yup.number(),
    }),
    workforce: yup.number().when('practice', {
        is: val => val > 0,
        then: yup
            .number()
            .min(1, 'This field is required')
            .required('This field is required'),
        otherwise: yup.number(),
    }),
});

const GQQuestion = ({
    goHome,
    gqSlug,
    title,
    next,
    eventId,
    initialValues,
    updateInitialValues,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <Formik
            validationSchema={QuestionSchema}
            initialValues={{
                practice: Number(initialValues.practice),
                frequency: Number(initialValues.frequency),
                workforce: Number(initialValues.workforce),
            }}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                let updatedValues = values;

                if (Number(values.practice) === 0) {
                    updatedValues.frequency = 0;
                    updatedValues.workforce = 0;
                }

                setSubmitting(true);
                dispatch(
                    postGrowthQuotient(eventId, { [gqSlug]: updatedValues }),
                ).then(
                    res => {
                        updateInitialValues({ [gqSlug]: updatedValues });
                        setSubmitting(false);
                        history.push(next);
                    },
                    err => {
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {({ values, isSubmitting }) => (
                <Form>
                    <QuestionBody>
                        <h5 className="header-pretitle">{title}</h5>
                        <Field
                            name="practice"
                            label={PRACTICE_QUESTION}
                            component={Radio}
                            options={PRACTICE_OPTIONS}
                        />
                        <ErrorMessage
                            name="practice"
                            component={ErrorMessageContainer}
                        />
                        {Number(values.practice) > 0 && (
                            <Fragment>
                                <Field
                                    name="frequency"
                                    label={FREQUENCY_QUESTION}
                                    component={Radio}
                                    options={FREQUENCY_OPTIONS}
                                />
                                <ErrorMessage
                                    name="frequency"
                                    component={ErrorMessageContainer}
                                />
                                <Field
                                    name="workforce"
                                    label={WORKFORCE_QUESTION}
                                    component={Radio}
                                    options={WORKFORCE_OPTIONS}
                                />
                                <ErrorMessage
                                    name="workforce"
                                    component={ErrorMessageContainer}
                                />
                            </Fragment>
                        )}
                    </QuestionBody>
                    <QuestionFooter
                        goHome={goHome}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default GQQuestion;
