import React from 'react';
import { Link } from 'react-router-dom';
import getKeyOfFirstTrue from '../utils/getKeyOfFirstTrue';

const ButtonLink = ({
    primary,
    secondary,
    success,
    danger,
    warning,
    info,
    light,
    dark,
    white,
    transparent,
    block,
    large,
    small,
    className = '',
    children,
    to,
}) => {
    const btnTypes = {
        primary,
        secondary,
        success,
        danger,
        warning,
        info,
        light,
        dark,
        white,
        transparent,
    };

    const btnType = getKeyOfFirstTrue(btnTypes);
    const c = [
        'text-decoration-none',
        'btn',
        `btn-${btnType || 'default'}`,
        'rounded-0',
        className || 'default',
        block ? 'btn-block' : '',
        large || small ? `btn-${large ? 'lg' : 'sm'}` : '',
    ].join(' ');

    return (
        <Link to={to} className={c}>
            {children}
        </Link>
    );
};

export default ButtonLink;
