import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import { Input, NumericalInput } from '../../components/Inputs';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postGrowthQuotient } from '../../reducers/itineraryActions';
import setResponseErrors from '../../utils/setResponseErrors';
import * as yup from 'yup';

const PreambleSchema = yup.object().shape({
    organization_name: yup.string().required('Organization name is required'),
    number_of_employees: yup
        .number('Must be a number.')
        .required('Number of employees is required'),
});

const GQPreamble = ({
    goHome,
    eventId,
    next,
    initialValues,
    updateInitialValues,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={PreambleSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                dispatch(postGrowthQuotient(eventId, values)).then(
                    res => {
                        setSubmitting(false);
                        updateInitialValues(values);
                        history.push(next);
                    },
                    err => {
                        setResponseErrors(err, setFieldError);
                        setSubmitting(false);
                    },
                );
            }}>
            {({ isSubmitting }) => (
                <Form>
                    <QuestionBody>
                        <h2>What's Your Growth Quotient (GQ)?</h2>
                        <p className="mb-4">
                            This is a 3-minute intake to calculate your
                            organization's Growth Quotient (GQ) across the 4
                            Growth Streams. Please note that the intake
                            references practices and behaviors of your
                            organization, not yourself.
                        </p>
                        <Field
                            component={Input}
                            name="organization_name"
                            label="Organization"
                            className="mb-4"
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="organization_name"
                            component={ErrorMessageContainer}
                        />

                        <Field
                            component={NumericalInput}
                            name="number_of_employees"
                            label="Number of employees"
                            className="mb-4"
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="number_of_employees"
                            component={ErrorMessageContainer}
                        />
                    </QuestionBody>
                    <QuestionFooter
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        goHome={goHome}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default GQPreamble;
