import React from 'react';
import { isArray } from 'highcharts';

const possibleErrorValue = err => typeof err === 'string' || isArray(err);

const ErrorMessageContainer = ({ children }) =>
    possibleErrorValue(children) ? (
        <p className="text-danger" style={{ marginTop: '-1.5rem' }}>
            {children}
        </p>
    ) : null;

export const GeneralErrorMessageContainer = ({ children }) => (
    <p className="text-danger">{children}</p>
);

export default ErrorMessageContainer;
