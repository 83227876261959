import React, { Fragment, useEffect, useState } from 'react';
import {
    QuestionHeader,
    QuestionBody,
    QuestionFooter,
} from './GrowthLabQuestionScreen';
import { useDispatch } from 'react-redux';
import { getMyGroup } from '../reducers/itineraryActions';
import Loader from '../components/Loader';

const BreakoutGroupsScreen = ({ currentArea, goToItineraryGroup }) => {
    const [group, setGroup] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMyGroup(currentArea.eventId)).then(data => setGroup(data));
    }, []);

    if (!group) return <Loader fullscreen />;

    return (
        <Fragment>
            <QuestionHeader
                title={currentArea.title}
                letter={currentArea.letter}
            />
            <QuestionBody>
                <div
                    className="d-flex flex-column
                align-items-center
                justify-content-center
                bg-ignite-blue text-light mb-4 p-5">
                    <span className="text-uppercase ">Group</span>
                    <span style={{ fontSize: '5rem', lineHeight: 1 }}>
                        {group.group}
                    </span>
                </div>
                <h2>{currentArea.title}</h2>
                <p>{currentArea.description}</p>
                <h4>Members</h4>
                <ul className="list-unstyled">
                    {group.members.map((g, i) => (
                        <li key={i} className="d-flex align-items-center mt-3">
                            <i className="fe fe-user" />
                            <span className="ml-3">{g.name}</span>
                        </li>
                    ))}
                </ul>
            </QuestionBody>
            <QuestionFooter
                goHome={goToItineraryGroup}
                onClickNext={goToItineraryGroup}
            />
        </Fragment>
    );
};

export default BreakoutGroupsScreen;
