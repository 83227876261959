import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import WelcomeScreen from './WelcomeScreen';
import SignUpScreen from './SignUpScreen';
import LoginScreen from './LoginScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import ContactUsScreen from './ContactUsScreen';

const PublicScreen = () => {
    return (
        <Switch>
            <Route path="/sign-up">
                <SignUpScreen />
            </Route>
            <Route path="/login">
                <LoginScreen />
            </Route>
            <Route path="/forgot_password">
                <ForgotPasswordScreen />
            </Route>
            <Route path="/contact-us">
                <ContactUsScreen />
            </Route>
            <Route path="/" exact>
                <WelcomeScreen />
            </Route>
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    );
};

export default PublicScreen;
