import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { QuestionHeader } from './GrowthLabQuestionScreen';
import GQPreamble from './GrowthQuotient/GQPreamble';
import GQQuestion from './GrowthQuotient/GQQuestion';
import ThankYouScreen from './ThankYouScreen';
import { useDispatch } from 'react-redux';
import { getGrowthQuotientAnswers } from '../reducers/itineraryActions';
import Loader from '../components/Loader';

const GQ_SLUGS = [
    { ordinality: 0, gqSlug: 'chix', title: 'CHI/X — Customer Centricity' },
    { ordinality: 1, gqSlug: 'inox', title: 'INNO/X — Innovation' },
    { ordinality: 2, gqSlug: 'colx', title: 'COLAB/X — Collaboration' },
    { ordinality: 3, gqSlug: 'pekx', title: 'PEAK/X — Purpose' },
].sort((a, b) => a.ordinality - b.ordinality);

const GrowthQuotientScreen = ({ goToItineraryGroup, currentArea, baseUrl }) => {
    const [isThankYou, setIsThankYou] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGrowthQuotientAnswers(currentArea.eventId)).then(data => {
            if (data) {
                const {
                    chix,
                    inox,
                    colx,
                    pekx,
                    organization_name,
                    number_of_employees,
                } = data;

                setInitialValues({
                    chix,
                    inox,
                    colx,
                    pekx,
                    organization_name,
                    number_of_employees,
                });
            } else {
                setInitialValues({
                    chix: { practice: 0, frequency: 0, workforce: 0 },
                    inox: { practice: 0, frequency: 0, workforce: 0 },
                    colx: { practice: 0, frequency: 0, workforce: 0 },
                    pekx: { practice: 0, frequency: 0, workforce: 0 },
                    organization_name: '',
                    number_of_employees: '',
                });
            }
        });
    }, []);

    const updateInitialValues = values => {
        setInitialValues({
            ...initialValues,
            ...values,
        });
    };

    if (!initialValues) return <Loader fullscreen />;

    return (
        <div className="w-100">
            {!isThankYou && (
                <QuestionHeader
                    title={currentArea.title}
                    letter={currentArea.letter}
                />
            )}
            <BrowserRouter basename={baseUrl}>
                <Switch>
                    <Route path={`/${currentArea.slug}`} exact>
                        <GQPreamble
                            initialValues={{
                                organization_name:
                                    initialValues.organization_name || '',
                                number_of_employees:
                                    initialValues.number_of_employees || '',
                            }}
                            updateInitialValues={updateInitialValues}
                            goHome={goToItineraryGroup}
                            eventId={currentArea.eventId}
                            next={`/${currentArea.slug}/${GQ_SLUGS[0].gqSlug}`}
                        />
                    </Route>
                    {GQ_SLUGS.map((s, i) => {
                        const isLast = i === GQ_SLUGS.length - 1;
                        const base = `/${currentArea.slug}`;
                        const next = isLast
                            ? `${base}/thank-you`
                            : `${base}/${GQ_SLUGS[i + 1].gqSlug}`;
                        const path = `${base}/${s.gqSlug}`;
                        return (
                            <Route path={path} key={i}>
                                <GQQuestion
                                    goHome={goToItineraryGroup}
                                    next={next}
                                    gqSlug={s.gqSlug}
                                    initialValues={initialValues[s.gqSlug]}
                                    updateInitialValues={updateInitialValues}
                                    title={s.title}
                                    eventId={currentArea.eventId}
                                />
                            </Route>
                        );
                    })}

                    <Route path={`/${currentArea.slug}/thank-you`}>
                        <ThankYouScreen
                            setIsThankYou={setIsThankYou}
                            goHome={goToItineraryGroup}
                            goToItineraryGroup={goToItineraryGroup}>
                            <p>
                                We've calculated your Growth Quotient (GQ) and
                                Maximum Growth (MG) scores - to be revealed when
                                we have more context later in this GrowthLab.
                            </p>
                        </ThankYouScreen>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default GrowthQuotientScreen;
