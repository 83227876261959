import axios from 'axios';
import { store } from './components/Provider';
import { LOGOUT } from './reducers/usersActions';

const instance = axios.create({
    timeout: 30000,
});

instance.interceptors.response.use(
    function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response && error.response.status === 401) {
            store.dispatch({ type: LOGOUT });
        }
        return Promise.reject(error);
    },
);

export default instance;
