import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ItineraryGroups from '../components/ItineraryGroups';
import { Link, useParams } from 'react-router-dom';
import Button from '../components/Button';
import { LOGOUT } from '../reducers/usersActions';

export const DashboardHeader = ({
    growthlab,
    children,
}) => {
    const { user } = useSelector(s => s.users);
    const { avatar } = user;
    const [showMenu, toggleMenu] = useState(false);
    const { groupSlug } = useParams();
    const dispatch = useDispatch();

    return (
        <div className="p-4 d-flex flex-column">
            <div className="dropdown d-flex">
                {growthlab && (
                    <Link to={`/groups/${groupSlug}`} className="text-body">
                        <i className="fe fe-arrow-left mr-auto" />
                    </Link>
                )}
                <button
                    onClick={() => toggleMenu(!showMenu)}
                    className="ml-auto avatar avatar-sm mb-3 border-0 bg-transparent p-0">
                    <img
                        src={avatar}
                        alt="..."
                        className="avatar-img rounded-circle"
                    />
                </button>
                <div
                    className={`dropdown-menu dropdown-menu-right ${
                        showMenu ? 'show' : ''
                    }`}
                    style={{
                        position: 'absolute',
                        willChange: 'transform',
                        top: '1.7rem',
                        right: 0,
                    }}>
                    <Link to="/profile" className="dropdown-item">
                        Profile
                    </Link>
                    <div className="dropdown-divider" />
                    <Button
                        className="px-4 text-left"
                        transparent
                        block
                        onClick={() => dispatch({ type: LOGOUT })}>
                        Logout
                    </Button>
                </div>
            </div>
            {children}
        </div>
    );
};

const DashboardScreen = () => {
    const user = useSelector(s => s.users.user);
    return (
        <div className="w-100" style={{ minHeight: '100vh' }}>
            <DashboardHeader>
                <h1 className="mb-3 mt-0">
                    {Math.random() > 0.5 ? 'Hello' : 'Hi'} {user.first_name}
                </h1>
                <p className="mt-0 mb-0">Welcome to Ignite House.</p>
            </DashboardHeader>
            <ItineraryGroups />
        </div>
    );
};

export default DashboardScreen;
