import React, { useEffect, useState } from 'react';
import { QuestionHeader } from './GrowthLabQuestionScreen';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    getEventDetails,
    populateDiscernmentAnswer,
} from '../reducers/itineraryActions';
import Loader from '../components/Loader';
import DiscernmentContent from './Discernment/DiscernmentContent';

const DiscernmentScreen = ({
    goToItineraryGroup,
    currentEvent,
    currentArea,
    baseUrl,
}) => {
    const rootPath = `/${currentArea.slug}`;
    const dispatch = useDispatch();

    const [sections, setSections] = useState([]);

    useEffect(() => {
        dispatch(
            getEventDetails(currentArea.discernment_id, currentEvent.id),
        ).then(data => {
            setSections(data.sections);
            dispatch(populateDiscernmentAnswer(data.answer));
        });
    }, []);

    if (!sections.length) return <Loader fullscreen />;

    const isSingle = sections.length === 1;

    return (
        <div className="w-100">
            <QuestionHeader
                title={currentArea.title}
                letter={currentArea.letter}
            />
            <BrowserRouter basename={baseUrl}>
                <Switch>
                    {isSingle
                        ? sections.map((section, i) => (
                              <Route key={i} path={rootPath} exact>
                                  <DiscernmentContent
                                      title={section.title}
                                      goHome={goToItineraryGroup}
                                      content={section.content}
                                      isLast
                                      slug={currentArea.slug}
                                      currentEventId={currentEvent.id}
                                  />
                              </Route>
                          ))
                        : sections
                              .sort((a, b) => a.ordinality - b.ordinality)
                              .map((section, i) => {
                                  const isFirst = i === 0;
                                  const isLast = i === sections.length - 1;

                                  const nextPath = !isLast
                                      ? `${rootPath}/${sections[i + 1].id}`
                                      : '';

                                  if (isFirst) {
                                      return (
                                          <Route key={i} path={rootPath} exact>
                                              <DiscernmentContent
                                                  title={section.title}
                                                  goHome={goToItineraryGroup}
                                                  next={nextPath}
                                                  content={section.content}
                                                  slug={currentArea.slug}
                                                  currentEventId={
                                                      currentEvent.id
                                                  }
                                              />
                                          </Route>
                                      );
                                  } else {
                                      return (
                                          <Route
                                              key={i}
                                              path={`${rootPath}/${section.id}`}>
                                              <DiscernmentContent
                                                  title={section.title}
                                                  goHome={goToItineraryGroup}
                                                  next={nextPath}
                                                  content={section.content}
                                                  isLast={isLast}
                                                  slug={currentArea.slug}
                                                  currentEventId={
                                                      currentEvent.id
                                                  }
                                              />
                                          </Route>
                                      );
                                  }
                              })}
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default DiscernmentScreen;
