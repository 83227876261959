export const MODAL_TYPES = {
    RESET_PW_SUCCESS: 'RESET_PW_SUCCESS',
    LOCK_MODAL: 'LOCK_MODAL',
};

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const showModal = type => ({ type: SHOW_MODAL, payload: { type } });
export const hideModal = () => ({ type: HIDE_MODAL });
export const showLockModal = lockDetails => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.LOCK_MODAL,
        lockDetails,
    },
});
