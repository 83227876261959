import React from 'react';
import ScreenHeader from '../components/ScreenHeader';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { showLockModal } from '../reducers/modalActions';

const Square = ({ image }) => (
    <span
        style={{
            display: 'block',
            width: '4rem',
            height: '4rem',
            backgroundColor: '#276EF1',
            borderRadius: '0.375rem',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(${image})`,
        }}
    />
);

const Itinerary = ({ name, prompt, is_locked, to, image }) => {
    const dispatch = useDispatch();
    const onClick = () => {
        if (is_locked) {
            dispatch(showLockModal(prompt));
        }
    };

    return (
        <li onClick={onClick} className="mb-3">
            <Link
                style={is_locked ? { pointerEvents: 'none' } : {}}
                to={to}
                disabled={is_locked}
                className="d-flex align-items-center text-body">
                <Square image={image} />
                <span className="ml-3">{name}</span>
            </Link>
        </li>
    );
};

const ItineraryGroupScreen = () => {
    const { groupSlug } = useParams();
    const { groups } = useSelector(s => s.itinerary);
    const currentGroup = groups.find(g => g.slug === groupSlug);
    const location = useLocation();
    const history = useHistory();

    const hasItineraries =
        currentGroup &&
        Object.keys(currentGroup).length &&
        currentGroup.itineraries &&
        currentGroup.itineraries.length;

    if (!hasItineraries) {
        return null;
    }

    return (
        <div className="py-1 px-4" style={{ minHeight: '100vh' }}>
            <ScreenHeader
                title={currentGroup.name}
                pretitle="Ignite House"
                goHome={() => history.push('/')}
            />
            <ul className="list-unstyled">
                {currentGroup.itineraries.map(itinerary => (
                    <Itinerary
                        key={itinerary.id}
                        {...itinerary}
                        to={`${location.pathname}/itinerary/${itinerary.id}`}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ItineraryGroupScreen;
