import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import appReducer from './appReducer';
import usersReducer from './usersReducer';
import modalReducer from './modalReducer';
import itineraryReducer from './itineraryReducer';

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['users'],
};

const appPersistConfig = {
    key: 'app',
    storage: storage,
    whitelist: ['auth_token', 'auth_token_v2'],
};

const rootReducer = combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    users: usersReducer,
    modal: modalReducer,
    itinerary: itineraryReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
