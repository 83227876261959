import React from 'react';
import { QuestionHeader } from './GrowthLabQuestionScreen';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import ScheduleCall from './GiveMeGrowth/ScheduleCall';
import GiveMeGrowthForm from './GiveMeGrowth/Form';

const GiveMeGrowthScreen = ({ goToItineraryGroup, currentArea, baseUrl }) => {
    const rootPath = `/${currentArea.slug}`;

    return (
        <div className="w-100">
            <QuestionHeader
                title={currentArea.title}
                letter={currentArea.letter}
            />
            <BrowserRouter basename={baseUrl}>
                <Switch>
                    <Route path={rootPath} exact>
                        <ScheduleCall
                            eventId={currentArea.eventId}
                            goToItineraryGroup={goToItineraryGroup}
                            next={`${rootPath}/form`}
                        />
                    </Route>
                    <Route path={`${rootPath}/form`}>
                        <GiveMeGrowthForm
                            eventId={currentArea.eventId}
                            goToItineraryGroup={goToItineraryGroup}
                        />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default GiveMeGrowthScreen;
