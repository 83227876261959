import React, { useState } from 'react';
import Button from './Button';

export const Input = ({
    type = 'text',
    field,
    className = '',
    id = '',
    label = '',
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>
            <input
                type={type}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};

export const Textarea = ({ field, className = '', id = '', label = '' }) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>

            <textarea
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};

export const NumericalInput = ({
    inputmode = 'numeric',
    pattern = '[0-9]*',
    field,
    className = '',
    id = '',
    label = '',
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>
            <input
                type="text"
                inputMode={inputmode}
                pattern={pattern}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};

export const PasswordInput = ({
    field,
    className = '',
    id = '',
    label = '',
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, togglePasswordView] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const toggleShowPassword = () => {
        togglePasswordView(!showPassword);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>
            <input
                className="pr-5"
                type={!showPassword ? 'password' : 'text'}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            <Button
                transparent
                large
                className="append-button"
                onClick={toggleShowPassword}>
                {showPassword ? (
                    <i className="fe fe-eye-off" />
                ) : (
                    <i className="fe fe-eye" />
                )}
            </Button>
        </div>
    );
};

export const Radio = ({ label, options, field, slider, disabled, form }) => {
    return (
        <div className="mb-3">
            <p className="font-weight-bold">{label}</p>
            <div
                className={`OptionsContainer ${
                    slider ? 'is-slider' : ''
                } mb-4`}>
                {options.map((o, i) => {
                    const checked = field.value === o.value;
                    return (
                        <label key={i} className="d-flex mb-3">
                            <input
                                type="radio"
                                disabled={disabled}
                                onChange={() =>
                                    form.setFieldValue(field.name, o.value)
                                }
                                checked={checked}
                                value={o.value}
                                style={{ display: 'none' }}
                            />
                            <i
                                className={`RadioInput ${
                                    checked ? 'isSelected' : ''
                                }`}
                            />

                            <span className="ml-3">{o.body || o.label}</span>
                        </label>
                    );
                })}
            </div>
        </div>
    );
};
