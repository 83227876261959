import React, { Fragment, useEffect, useState } from 'react';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import Button from '../../components/Button';
import { useDispatch } from 'react-redux';
import {
    scheduleCall,
    getCoupons,
    getPayItForward,
} from '../../reducers/itineraryActions';
import Loader from '../../components/Loader';

const GROWTH_OPTIONS = [
    {
        title: 'One day Growth Lab',
        content:
            'Iconic full-day immersion integrating all 4 Foundational Growth Streams, a tour-de-force showcase of how to operationalize, measure and scale Growth.',
    },
    {
        title: 'Delta Force Sprints',
        content:
            '4-8-12 Week Innovation Sprints that take your teams through agile cycles of prototype-feedback-iterate as we drive towards launching a new product, project or campaign.',
    },
    {
        title: 'Centre for Human-Centered Design',
        content:
            'Iconic half-day to multi-day experiences in Human-Centered Design Thinking integrating global best practices.',
    },
];

const Coupon = ({ eventId }) => {
    const [coupon, setCoupon] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCoupons(eventId)).then(data => setCoupon(data));
    }, []);

    return (
        <div className="py-5 text-center">
            {coupon ? (
                <Fragment>
                    <p className="small">
                        You've also unlocked a special gift:
                    </p>
                    <h3>{coupon.code}</h3>
                    <p className="text-black-50 small">
                        Valid until {coupon.valid_until}
                    </p>
                    <p className="font-weight-bold small">
                        Save this code to get 10% off on your next Ignite
                    </p>
                </Fragment>
            ) : (
                <Loader />
            )}
        </div>
    );
};

const ScheduleCall = ({ eventId, goToItineraryGroup, next }) => {
    const [scheduling, setScheduling] = useState(false);
    const [done, setDone] = useState(false);
    const dispatch = useDispatch();

    const handleClick = (eventId) => {
        setScheduling(true);
        dispatch(scheduleCall(eventId)).then(
            res => {
                setScheduling(false);
                setDone(true);
            },
            err => {
                setScheduling(false);
            },
        );
    };

    useEffect(() => {
        dispatch(getPayItForward(eventId)).then(res => {
            setDone(res.schedule_a_call);
        });
    }, [eventId]);

    return (
        <Fragment>
            <QuestionBody>
                <h2>Here are your Top 3 Growth Options c/o Ignite</h2>
                <ol className="pl-0">
                    {GROWTH_OPTIONS.map((opt, i) => (
                        <li key={i} className="mb-4">
                            <h3>{opt.title}</h3>
                            <p style={{ color: 'rgba(60,60,67,0.6)' }}>
                                {opt.content}
                            </p>
                        </li>
                    ))}
                </ol>
                <h2 className="mt-5">
                    Beyond the above 3, if you want maximum Growth, your top
                    choice will always be the:
                </h2>

                <div
                    className="px-3 py-5 text-center bg-light"
                    style={{ marginLeft: '-1.5rem', marginRight: '-1.5rem' }}>
                    <img
                        style={{ maxWidth: '80%' }}
                        src="/ignite100.png"
                        alt="ignite 100 logo"
                    />
                    <p className="text-black-50 mt-3 mb-3">to learn more</p>
                    <Button
                        primary
                        rounded
                        isLoading={scheduling}
                        onClick={e => handleClick(eventId)}
                        disabled={done}>
                        {done ? (
                            <span>Call Scheduled</span>
                        ) : (
                            <span>Schedule a Call</span>
                        )}
                    </Button>
                </div>

                <Coupon eventId={eventId} />
            </QuestionBody>
            <QuestionFooter goHome={goToItineraryGroup} to={next} />
        </Fragment>
    );
};

export default ScheduleCall;
