import React, { useEffect, Fragment } from 'react';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, FieldArray } from 'formik';
import { OpenText } from '../../components/QuestionInput';
import { Input, NumericalInput } from '../../components/Inputs';

const InviteForm = ({ goToItineraryGroup, next }) => {
    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={{
                invitees: [{ name: '', email: '', contact: '' }],
            }}
            onSubmit={values => {
                goToItineraryGroup();
            }}>
            {({ values }) => (
                <Form>
                    <QuestionBody>
                        <p className="font-weight-bold mb-4">
                            Give the gift that keeps giving. Who comes to mind
                            among friends and family who I'd like to experience
                            Ignite?
                        </p>
                        <FieldArray
                            name="invitees"
                            render={arrayHelpers => (
                                <div>
                                    {values.invitees &&
                                    values.invitees.length > 0
                                        ? values.invitees.map((invitee, i) => (
                                              <div key={i}>
                                                  <Field
                                                      name={`invitees.${i}.name`}
                                                      component={Input}
                                                      label="Name"
                                                  />
                                                  <br />
                                                  <Field
                                                      name={`invitees.${i}.email`}
                                                      component={Input}
                                                      type="email"
                                                      label="Email Address"
                                                  />
                                                  <br />
                                                  <Field
                                                      name={`invitees.${i}.contact`}
                                                      component={NumericalInput}
                                                      label="Contact Number"
                                                  />
                                              </div>
                                          ))
                                        : null}
                                </div>
                            )}
                        />
                    </QuestionBody>
                    <QuestionFooter goHome={goToItineraryGroup} />
                </Form>
            )}
        </Formik>
    );
};

export default InviteForm;
