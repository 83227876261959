import { SHOW_MODAL, HIDE_MODAL } from './modalActions';

const initialState = {
    show: false,
    type: '',
    lockDetails: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                show: true,
                type: action.payload.type,
                lockDetails: action.payload.lockDetails,
            };
        case HIDE_MODAL:
            return {
                ...state,
                show: false,
                type: '',
                lockDetails: {},
            };
        default:
            return state;
    }
};

export default reducer;
