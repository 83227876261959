import {
    SIGN_UP_SUCCESSFUL,
    LOGIN_SUCCESSFUL,
    LOGOUT,
    LOGIN_SUCCESSFUL_V2,
    FB_SIGNUP_SUCCESSFUL,
} from './usersActions';

const initialState = {
    auth_token: '',
    auth_token_v2: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP_SUCCESSFUL:
        case LOGIN_SUCCESSFUL:
        case FB_SIGNUP_SUCCESSFUL:
            return {
                ...state,
                auth_token: action.payload.auth_token,
            };
        case LOGIN_SUCCESSFUL_V2:
            return {
                ...state,
                auth_token_v2: action.payload.auth_token,
            };
        case LOGOUT:
            return {
                ...state,
                auth_token: '',
            };
        default:
            return state;
    }
};

export default reducer;
