import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import ScreenHeader from '../components/ScreenHeader';
import * as yup from 'yup';
import { Input, PasswordInput, Textarea } from '../components/Inputs';
import Button from '../components/Button';
import { login } from '../reducers/usersActions';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_SUCCESSFUL } from '../reducers/usersActions';
import ErrorMessageContainer, {
    GeneralErrorMessageContainer,
} from '../components/ErrorMessageContainer';
import setResponseErrors from '../utils/setResponseErrors';

const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .email('Must input a valid email address.')
        .required('Email is required.'),
    fullName: yup.string().required('Full name is required.'),
    message: yup.string().required('Message is required.'),
});

const ContactUsScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(s => s.users.user);
    return (
        <div className="py-1 px-4" style={{ height: '100vh' }}>
            <ScreenHeader title="We're here to help">
                <p className="mt-3 mb-0">Let us know how we may help you</p>
            </ScreenHeader>
            <Formik
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {}}
                initialValues={{
                    email: user ? user.email : '',
                    fullName: user
                        ? `${user.first_name || ''} ${user.last_name || ''}`
                        : '',
                    message: '',
                }}>
                {({ isSubmitting }) => (
                    <Form
                        className="d-flex flex-column "
                        style={{ minHeight: 'calc(100% - 200px)' }}>
                        <Field
                            name="fullName"
                            label="Full Name"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="fullName"
                            component={ErrorMessageContainer}
                        />
                        <Field
                            name="email"
                            label="Email Address"
                            type="email"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="email"
                            component={ErrorMessageContainer}
                        />
                        <Field
                            name="message"
                            label="Message"
                            className="mb-4"
                            component={Textarea}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="message"
                            component={ErrorMessageContainer}
                        />

                        <Button
                            block
                            large
                            type="submit"
                            className="mt-auto"
                            disabled={isSubmitting}>
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            <span> Send</span>
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactUsScreen;
