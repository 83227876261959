import React, { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube';

const defaultOpts = {
    height: '0',
    width: '0',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
    },
};

const Container = ({ id, title }) => {
    const [opts, setOpts] = useState(defaultOpts);
    const videoRef = useRef(null);
    const _onReady = event => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    };

    useEffect(() => {
        const { width } = videoRef.current.getBoundingClientRect();
        setOpts({
            ...opts,
            width: width,
            height: width / 1.6,
        });
    }, [videoRef.current]);

    return (
        <div className="VideoContainer mt-5" ref={videoRef}>
            <YouTube videoId={id} opts={opts} onReady={_onReady} />
            <h2 className="mt-4 mb-3">{title}</h2>
            <p>
                This 2-minute video explains the Growth Quotient (GQ) and plots
                a course for Maximum Growth (MG) across the 4 Growth Streams.
            </p>
        </div>
    );
};

export default Container;
