import instance from '../instance';

export const POPULATE_ITINERARY_GROUPS = 'POPULATE_ITINERARY_GROUPS';
export const POPULATE_DISCERNMENT_ANSWER = 'POPULATE_DISCERNMENT_ANSWER';
export const UPDATE_DISCERNMENT_ANSWER = 'UPDATE_DISCERNMENT_ANSWER';
export const TOGGLE_APP_AREA_LOCK = 'TOGGLE_APP_AREA_LOCK';

export const populateDiscernmentAnswer = answer => {
    return {
        type: POPULATE_DISCERNMENT_ANSWER,
        payload: { [answer.discernment_slug]: answer.answers },
    };
};

export const updateDiscernmentAnswer = data => ({
    type: UPDATE_DISCERNMENT_ANSWER,
    payload: data,
});

export const getEventDetails = (discernmentId, eventId) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/discernments/${discernmentId}`,
            method: 'GET',
            params: {
                event_id: eventId,
            },
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getMyGroup = eventId => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/events/${eventId}/groupings`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getStatistics = eventId => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/growth_calculator`,
            method: 'GET',
            params: {
                event_id: eventId,
            },
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getGrowthQuotientAnswers = eventId => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/growth_calculator/mine`,
            method: 'GET',
            params: {
                event_id: eventId,
            },
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

/*
    organization_name,
    number_of_employees,
    chix: { practice, frequency, workforce },
    inox: { practice, frequency, workforce },
    colx: { practice, frequency, workforce },
    pekx: { practice, frequency, workforce },
*/

export const postGrowthQuotient = (eventId, data) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/growth_calculator`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
            data: {
                event_id: eventId,
                ...data,
            },
        })
        .then(res => res.data);
};

export const getCoupons = eventId => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/events/${eventId}/coupons`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const scheduleCall = eventId => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/pay_it_forward/schedule_a_call`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
            data: {
                event_id: eventId,
            },
        })
        .then(res => res.data);
};

export const giveMeGrowth = (eventId, data) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/pay_it_forward/give_me_growth`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
            data: {
                event_id: eventId,
                ...data,
            },
        })
        .then(res => res.data);
};

export const postPayItForward = (eventId, data) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/pay_it_forward/pay_it_forward`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
            data: {
                event_id: eventId,
                ...data,
            },
        })
        .then(res => res.data);
};

export const getPayItForward = eventId => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/pay_it_forward`,
            params: {
                event_id: eventId,
            },
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getDiscernmentAnswers = discernmentSlug => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/answers`,
            params: {
                discernment_slug: discernmentSlug,
            },
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

const answerEndpoint = (eventId, data, url) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url,
            data: { event_id: eventId, ...data },
            method: 'POST',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const saveAnswers = (eventId, data) =>
    answerEndpoint(eventId, data, '/api/v3/answers/save');
export const submitAnswers = (eventId, data) =>
    answerEndpoint(eventId, data, '/api/v3/answers/submit');
