import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import ScreenHeader from '../components/ScreenHeader';
import * as yup from 'yup';
import { Input, PasswordInput } from '../components/Inputs';
import Button from '../components/Button';
import { login, loginV2 } from '../reducers/usersActions';
import { useDispatch } from 'react-redux';
import {
    LOGIN_SUCCESSFUL,
    LOGIN_SUCCESSFUL_V2,
} from '../reducers/usersActions';
import ErrorMessageContainer, {
    GeneralErrorMessageContainer,
} from '../components/ErrorMessageContainer';
import setResponseErrors from '../utils/setResponseErrors';
import Axios from 'axios';

const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .email('Must input a valid email address.')
        .required('Email is required.'),
    password: yup.string().required('Password is required.'),
});

const LoginScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <div className="py-1 px-4" style={{ height: '100vh' }}>
            <ScreenHeader title="Welcome Back" pretitle="Ignite House" />
            <Formik
                validationSchema={LoginSchema}
                onSubmit={(
                    { user_authentication, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    setSubmitting(true);
                    Axios.all([login(values), loginV2(values)]).then(
                        Axios.spread(function(v1, v2) {
                            console.log({ v2 });
                            setSubmitting(false);
                            dispatch({ type: LOGIN_SUCCESSFUL, payload: v1 });
                            dispatch({
                                type: LOGIN_SUCCESSFUL_V2,
                                payload: v2,
                            });
                            history.replace('/');
                        }),
                        err => {
                            setResponseErrors(err, setFieldError);
                            setSubmitting(false);
                        },
                    );
                }}
                initialValues={{
                    email: '',
                    password: '',
                    user_authentication: '',
                }}>
                {({ isSubmitting }) => (
                    <Form
                        className="d-flex flex-column "
                        style={{ minHeight: 'calc(100% - 200px)' }}>
                        <Field
                            name="email"
                            label="Email Address"
                            type="email"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="email"
                            component={ErrorMessageContainer}
                        />
                        <Field
                            name="password"
                            label="Password"
                            className="mb-4"
                            component={PasswordInput}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="password"
                            component={ErrorMessageContainer}
                        />
                        <ErrorMessage
                            name="user_authentication"
                            component={GeneralErrorMessageContainer}
                        />
                        <Link
                            to="/forgot_password"
                            style={{
                                color: '#8a8a8e',
                                display: 'inline-block',
                                marginLeft: 'auto',
                            }}>
                            I forgot my password
                        </Link>

                        <Button
                            block
                            large
                            type="submit"
                            className="mt-auto"
                            disabled={isSubmitting}>
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            <span> Sign In</span>
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LoginScreen;
