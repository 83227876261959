import React, { useCallback, useEffect, useState } from 'react';
import { QuestionFooter } from './GrowthLabQuestionScreen';
import useWindowEvent from '../hooks/useWindowEvent';

const ThankYouScreen = ({
    children,
    goHome,
    goToItineraryGroup,
    setIsThankYou,
}) => {
    const [height, setHeight] = useState('100vh');
    useEffect(() => {
        setIsThankYou(true);

        return () => {
            setIsThankYou(false);
        };
    }, []);

    const callback = useCallback(() => {
        setHeight(`${window.innerHeight}px`);
    }, []);
    useWindowEvent('resize', callback);

    return (
        <div className="d-flex flex-column" style={{ height }}>
            <div className="mb-auto p-5">
                <h2>Thank you!</h2>
                {children}
            </div>
            <QuestionFooter goHome={goHome} onClickNext={goToItineraryGroup} />
        </div>
    );
};

export default ThankYouScreen;
