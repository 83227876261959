import React from 'react';
import { useParams } from 'react-router-dom';
import useCurrentItinerary from '../hooks/useCurrentItinerary';
import GrowthQuotientScreen from './GrowthQuotientScreen';
import Loader from '../components/Loader';
import DiscernmentScreen from './DiscernmentScreen';
import BreakoutGroupsScreen from './BreakoutGroupsScreen';
import GrowthDataVizScreen from './GrowthDataVizScreen';
import GiveMeGrowthScreen from './GiveMeGrowthScreen';
import PayItForwardScreen from './PayItForwardScreen';
import DeltaDeclarationsScreen from './DeltaDeclarationsScreen';
import ChartScreen from './ChartScreen';

const CurrentAreaScreen = () => {
    const params = useParams();
    const { groupSlug, itineraryId, slug } = params;
    const {
        goToItineraryGroup,
        currentItinerary,
        baseUrl,
    } = useCurrentItinerary({ groupSlug, itineraryId });

    if (!currentItinerary) {
        return <Loader fullscreen />;
    }

    const currentEvent = currentItinerary.event;

    if (!currentEvent) {
        return <Loader fullscreen />;
    }

    const currentArea = currentEvent.app_areas.find(area => area.slug === slug);

    switch (currentArea.type) {
        case 'growth_calculator':
            return (
                <GrowthQuotientScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'discernment':
            return (
                <DiscernmentScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentEvent={currentEvent}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'breakout_groups':
            return (
                <BreakoutGroupsScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                />
            );
        case 'growth_dataviz':
            return (
                <GrowthDataVizScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                />
            );
        case 'give_me_growth':
            return (
                <GiveMeGrowthScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'growth_payforward':
            return (
                <PayItForwardScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'charts':
            return (
                <ChartScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'delta_declarations':
            return (
                <DiscernmentScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentEvent={currentEvent}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        default:
            return null;
    }
};

export default CurrentAreaScreen;
