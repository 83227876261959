import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useCurrentItinerary = ({ groupSlug, itineraryId }) => {
    const itineraries = useSelector(s => s.itinerary.itineraries);
    const currentItinerary = itineraries.find(i => i.id === itineraryId);
    const baseUrl = `/groups/${groupSlug}/itinerary/${itineraryId}`;
    const history = useHistory();
    const goHome = () => history.push('/');
    const goToItineraryGroup = () => history.push(baseUrl);

    return {
        baseUrl,
        currentItinerary,
        goHome,
        goToItineraryGroup,
    };
};

export default useCurrentItinerary;
