const setResponseErrors = (err, setFieldError) => {
    if (err.response && err.response.data && err.response.data.error) {
        const errors = err.response.data.error;
        Object.keys(err.response.data.error).forEach(k =>
            setFieldError(k, errors[k]),
        );
    }

    return err;
};

export default setResponseErrors;
