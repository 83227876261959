import React, { Fragment, useEffect, useState } from 'react';
import {
    QuestionHeader,
    QuestionBody,
    QuestionFooter,
} from './GrowthLabQuestionScreen';
import { useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import { getStatistics } from '../reducers/itineraryActions';
import Youtube from '../components/Youtube';

const DATA_VIZ_DETAILS = [
    {
        id: 'chix',
        name: 'CHI/X',
        content: `Growth practiced daily - driven by presence, empathy, and humanity at the core of customer relationships and the fuel for all growth and innovation.`,
        color: (opacity = 1) => `rgba(248,25,20,${opacity})`,
    },
    {
        id: 'pekx',
        name: 'PEAK/X',
        content: `Growth practiced daily - driven by people unleashed to pursue their greatest potential, who continuously discover and apply the full force of their gifts and compassions in ways that advance the company's mission.`,
        color: (opacity = 1) => `rgba(22, 99,9,${opacity})`,
    },
    {
        id: 'inox',
        name: 'INNO/X',
        content: `Growth practiced daily - driven by innovation practices operationalized as a way of life and integrated into the way we work.`,
        color: (opacity = 1) => `rgba(147,136,213,${opacity})`,
    },
    {
        id: 'colx',
        name: 'COLAB/X',
        content: `Growth practiced daily - driven by open networks that break silos and give people the ability to collaborate based on strengths, aspirations, and needs.`,
        color: (opacity = 1) => `rgba(248,157,55,${opacity})`,
    },
    {
        id: 'overall',
        name: '4GS',
        content: `The 4 Foundational Growth Streams - Customer Science, Innovation Science, Collaboration Science, and Purpose Science - operationalized, measured, and scaled as daily practices of your workforce and applied to the unique realities of your markets and teams.`,
        color: (opacity = 1) => `rgba(102,102,102,${opacity})`,
    },
];

const getYtId = link => link.split('=')[1];

const Bar = ({
    name,
    personal,
    percentage,
    children,
    color,
    isActive,
    onClick,
}) => (
    <button
        className="border-0 bg-transparent text-left d-flex flex-column mb-3 align-items-stretch w-100 p-0 justify-content-center"
        onClick={onClick}>
        {/* <span>{personal}</span> */}
        <div className="d-flex w-100 mt-3 align-items-center">
            <div className="data-bar mr-3">
                <span className="viz-name">{name}</span>
                <span
                    className="data-bar-overlay"
                    style={{
                        width: `${Number(percentage)}%`,
                        backgroundColor: color(1),
                    }}>
                    <span
                        className="percentage"
                        style={{ right: percentage > 90 ? '0' : '-1.5rem' }}>
                        {percentage}
                    </span>
                </span>
            </div>
            <i
                className="fe fe-chevron-down"
                style={{ fontSize: '1rem', color: '#87878B' }}
            />
        </div>
        <div className="pt-2 small" style={{ color: '#87878B' }}>
            {isActive && children}
        </div>
    </button>
);

const GrowthDataVizScreen = ({ currentArea, goToItineraryGroup }) => {
    const dispatch = useDispatch();
    const [activeViz, setActiveViz] = useState(null);
    const [data, setData] = useState({
        attendees: 0,
        total: {},
        average: {},
        percentAverage: {},
        breakdown: {},
        averageBreakdown: {},
        personal: {},
        videoURL: '',
    });

    console.log(data);

    useEffect(() => {
        dispatch(getStatistics(currentArea.eventId)).then(d => setData(d));
    }, []);
    return (
        <Fragment>
            <QuestionHeader
                title={currentArea.title}
                letter={currentArea.letter}
            />
            <QuestionBody>
                <h2>{currentArea.title}</h2>
                <p>
                    The number on the top is your organization's Growth Quotient
                    (GQ), indicating growth inputs (or opportunities) being
                    created across the 4 Growth Streams given current practices
                    and behaviors. The higher your GQ, the more Growth
                    Opportunities you are creating.
                </p>

                {DATA_VIZ_DETAILS.map((d, i) => {
                    const isActive = activeViz === d.id;
                    return (
                        <Bar
                            isActive={isActive}
                            onClick={() => setActiveViz(isActive ? null : d.id)}
                            key={i}
                            color={d.color}
                            percentage={data.percentAverage[d.id] || 0}
                            personal={
                                (data.personal.percent &&
                                    data.personal.percent[d.id]) ||
                                0
                            }
                            name={d.name}>
                            <p className="m-0">{d.content}</p>
                        </Bar>
                    );
                })}
                {data.videoURL && (
                    <Youtube
                        id={getYtId(data.videoURL)}
                        title="Sample Video Title"
                    />
                )}
            </QuestionBody>
            <QuestionFooter
                goHome={goToItineraryGroup}
                onClickNext={goToItineraryGroup}
            />
        </Fragment>
    );
};

export default GrowthDataVizScreen;
