import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import DashboardScreen from './DashboardScreen';
import ItineraryGroupScreen from './ItineraryGroupScreen';
import ContactUsScreen from './ContactUsScreen';
import {
    POPULATE_ITINERARY_GROUPS,
    TOGGLE_APP_AREA_LOCK,
} from '../reducers/itineraryActions';
import { useDispatch } from 'react-redux';
import { getItineraryGroups } from '../reducers/usersActions';
import GrowthLabScreen from './GrowthLabScreen';
import CurrentAreaScreen from './CurrentAreaScreen';
import ProfileScreen from './ProfileScreen';
import consumer from '../channels/consumer';

const CHANNEL = { channel: 'AppAreasChannel' };

const PrivateScreen = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getItineraryGroups()).then(data => {
            dispatch({
                type: POPULATE_ITINERARY_GROUPS,
                payload: data.groups,
            });
        });
    }, []);

    useEffect(() => {
        const methods = {
            initialized() {},
            connected() {},
            disconnected() {},
            rejected() {},
            update() {},
            received(data) {
                dispatch({
                    type: TOGGLE_APP_AREA_LOCK,
                    payload: data,
                });
            },
        };

        consumer.subscriptions.create(CHANNEL, methods);
    }, []);

    return (
        <Switch>
            <Route path="/groups/:groupSlug/itinerary/:itineraryId/:slug">
                <CurrentAreaScreen />
            </Route>
            <Route path="/groups/:groupSlug/itinerary/:itineraryId">
                <GrowthLabScreen />
            </Route>
            <Route path="/groups/:groupSlug">
                <ItineraryGroupScreen />
            </Route>
            <Route path="/contact-us">
                <ContactUsScreen />
            </Route>
            <Route path="/profile">
                <ProfileScreen />
            </Route>
            <Route path="/" exact>
                <DashboardScreen />
            </Route>
        </Switch>
    );
};

export default PrivateScreen;
