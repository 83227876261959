import React from 'react';
import { Link } from 'react-router-dom';
import getKeyOfFirstTrue from '../utils/getKeyOfFirstTrue';
import Loader from './Loader';

const Button = ({
    primary,
    secondary,
    success,
    danger,
    warning,
    info,
    light,
    dark,
    white,
    transparent,
    block,
    large,
    small,
    className = '',
    children,
    type = 'button',
    outline,
    disabled,
    onClick = () => {},
    to = '',
    rounded = false,
    isLoading = false,
}) => {
    const btnTypes = {
        primary,
        secondary,
        success,
        danger,
        warning,
        info,
        light,
        dark,
        white,
        transparent,
    };

    const btnType = getKeyOfFirstTrue(btnTypes);
    const c = [
        'btn',
        `btn-${outline ? 'outline-' : ''}${btnType || 'default'}`,
        !rounded ? 'rounded-0' : '',
        className || 'default',
        block ? 'btn-block' : '',
        large || small ? `btn-${large ? 'lg' : 'sm'}` : '',
    ].join(' ');

    return to ? (
        <Link to={to} className={c}>
            {isLoading ? <Loader /> : children}
        </Link>
    ) : (
        <button className={c} type={type} onClick={onClick} disabled={disabled}>
            {isLoading ? <Loader /> : children}
        </button>
    );
};

export default Button;
