import React, { useEffect, useState } from 'react';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import { useDispatch } from 'react-redux';
import {
    getPayItForward,
    postPayItForward,
} from '../../reducers/itineraryActions';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { OpenText } from '../../components/QuestionInput';
import Loader from '../../components/Loader';

const FeedbackForm = ({ eventId, goToItineraryGroup, next }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [initialValues, setInitialValues] = useState(null);
    useEffect(() => {
        dispatch(getPayItForward(eventId)).then(
            res => {
                setInitialValues({
                    this_worked_for_me: res.this_worked_for_me || '',
                    this_needs_improvement: res.this_needs_improvement || '',
                    heres_an_idea: res.heres_an_idea || '',
                });
            },
            err => {
                setInitialValues({
                    this_worked_for_me: '',
                    this_needs_improvement: '',
                    heres_an_idea: '',
                });
            },
        );
    }, []);

    if (!initialValues) {
        return (
            <QuestionBody>
                <Loader fullscreen />
            </QuestionBody>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                dispatch(postPayItForward(eventId, values)).then(
                    res => {
                        setSubmitting(false);
                        history.push(next);
                    },
                    err => {
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {({ isSubmitting }) => (
                <Form>
                    <QuestionBody>
                        <p className="font-weight-bold">
                            Your candid thoughts will help us upgrade the
                            experience for all
                        </p>
                        <Field
                            label="This worked best for me"
                            name="this_worked_for_me"
                            labelClassName="font-weight-bold"
                            component={OpenText}
                            disabled={isSubmitting}
                        />
                        <Field
                            label="This needs improvement"
                            name="this_needs_improvement"
                            labelClassName="font-weight-bold"
                            component={OpenText}
                            disabled={isSubmitting}
                        />
                        <Field
                            label="Here's an idea for you guys"
                            name="heres_an_idea"
                            labelClassName="font-weight-bold"
                            component={OpenText}
                            disabled={isSubmitting}
                        />
                    </QuestionBody>
                    <QuestionFooter
                        goHome={goToItineraryGroup}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default FeedbackForm;
