import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';
import useWindowEvent from '../hooks/useWindowEvent';

export const QuestionHeader = ({ letter, title }) => {
    const history = useHistory();
    const goBack = e => {
        e.preventDefault();
        history.goBack();
    };
    return (
        <header className="header-body border-0 mb-0 px-4">
            <div className="d-flex align-items-center">
                <Button
                    transparent
                    large
                    className="border-0 p-0 mr-3"
                    onClick={goBack}>
                    <i className="fe fe-arrow-left" />
                </Button>
                <strong className="mb-0">
                    {letter}. {title}
                </strong>
            </div>
        </header>
    );
};

export const QuestionFooter = ({
    disabled,
    isLoading,
    goHome,
    onClickNext = () => {},
    to = '',
}) => (
    <footer className="w-100 py-3 px-4 mt-auto d-flex align-items-center">
        <Button large light className="mr-3" onClick={goHome}>
            <span className="fe fe-home"></span>
        </Button>
        {to ? (
            <Button large block disabled={disabled} to={to}>
                <span>Next</span>
            </Button>
        ) : (
            <Button
                isLoading={isLoading}
                type="submit"
                large
                block
                disabled={disabled}
                onClick={onClickNext}>
                <span>Next</span>
            </Button>
        )}
    </footer>
);

export const QuestionBody = ({ children }) => {
    const [height, setHeight] = useState('100vh');
    const callback = useCallback(() => {
        setHeight(`${window.innerHeight}px`);
    }, []);
    useWindowEvent('resize', callback);

    return (
        <div
            className="px-4 py-2"
            style={{
                overflow: 'scroll',
                height: `calc(${height} - 75px - 69px)`,
            }}>
            {children}
        </div>
    );
};
