import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { QuestionHeader } from './GrowthLabQuestionScreen';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import { getDataViz } from '../reducers/usersActions';
import ReactWordcloud from 'react-wordcloud';
import { VictoryChart, VictoryTheme, VictoryBar, VictoryTooltip } from 'victory';

const Chart = ({ type, data }) => {
    const [barWidth, setBarWidth] = useState(400);

    const ref = useRef(null);
    useEffect(() => {
        console.log('width', ref.current ? ref.current.offsetWidth : 0);
        setBarWidth((ref.current && ref.current.offsetWidth) || 400);
    }, [ref.current]);

    switch (type) {
        case 'wordcloud':
            const words = Object.keys(data).map(word => {
                return { text: word, value: data[word] };
            });
            const wcOptions = {
                rotations: 3,
                fontSizes: [5, 60],
                padding: 1,
                rotationAngles: [0, 90],
            };
            return (
                <div style={{ height: '10rem' }}>
                    <ReactWordcloud words={words} options={wcOptions} />
                </div>
            );
        case 'vertical_bar':
            const vBarTotal = data.map(d => d.count).reduce((a,b) => a + b, 0);
            const vBarData = data.map(d => {
                return { x: d.key, y: (d.count/vBarTotal * 100) };
            });

            return (
                <VictoryChart
                    theme={VictoryTheme.material}
                    domain={{ y: [0,100] }}
                    responsive>
                    <VictoryBar
                        style={{
                            data: { fill: 'blue' },
                            labels: { fill: 'black' }
                        }}
                        data={vBarData}
                        alignment='start'
                        labels={({ datum }) => `${datum.x}: ${datum.y.toFixed(1)}%` }
                        labelComponent={<VictoryTooltip constrainToVisibleArea />}
                    />
                </VictoryChart>
            );
        case 'bar':
        case 'horizontal_bar':
            const barTotal = data.map(d => d.count).reduce((a,b) => a + b, 0);
            const barData = data.map(d => {
                return { x: d.body, y: (d.count/barTotal * 100) };
            }).filter(d => d.y > 0);

            return (
                <VictoryChart
                    theme={VictoryTheme.material}
                    domainPadding={10}
                    height={barData.length * 60}
                    responsive>
                    <VictoryBar
                        horizontal
                        style={{
                            data: { fill: 'blue' },
                            labels: { fill: 'black' }
                        }}
                        data={barData}
                        sortKey="y"
                        sortOrder="descending"
                        labels={({ datum }) => `${datum.x}: ${datum.y.toFixed(1)}%` }
                        labelComponent={<VictoryTooltip constrainToVisibleArea />}
                    />
                </VictoryChart>
            );
        default:
            return null;
    }
};

const DataVisualization = ({ reports }) => (
    <Fragment>
        {reports.map((r, i) => {
            console.log(r);
            const { report, question } = r;
            return (
                <div
                    key={i}
                    className={`DataVizContainer DataVizContainer-${report.type}`}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: question.body,
                        }}></span>
                    <br />
                    <Chart type={report.type} data={report.data} />
                </div>
            );
        })}
    </Fragment>
);

const ChartScreen = ({ goToItineraryGroup, currentArea, baseUrl }) => {
    const rootPath = `/${currentArea.slug}`;
    const dispatch = useDispatch();
    const [reports, setReports] = useState([]);

    useEffect(() => {
        dispatch(getDataViz(currentArea.data_visualization_id)).then(data =>
            setReports(data),
        );
    }, []);

    return (
        <div className="w-100">
            <QuestionHeader
                title={currentArea.title}
                letter={currentArea.letter}
            />
            <BrowserRouter basename={baseUrl}>
                <Switch>
                    <Route path={rootPath} exact>
                        <DataVisualization reports={reports} />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default ChartScreen;
