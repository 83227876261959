import React from 'react';
import * as yup from 'yup';
import ScreenHeader from '../components/ScreenHeader';
import { PasswordInput, Input, NumericalInput } from '../components/Inputs';
import { Form, Field, Formik, ErrorMessage } from 'formik';
import ErrorMessageContainer from '../components/ErrorMessageContainer';
import Button from '../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import setResponseErrors from '../utils/setResponseErrors';
import {
    updateProfile,
    UPDATE_USER_SUCCESSFUL,
} from '../reducers/usersActions';
import { useHistory } from 'react-router-dom';

const ProfileSchema = yup.object().shape({
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup.string().email(),
    password: yup.string(),
    contact: yup.string(),
    title: yup.string(),
    company: yup.string(),
});

const ProfileScreen = () => {
    const { user } = useSelector(s => s.users);
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className="py-1 px-4" style={{ height: '100vh' }}>
            <ScreenHeader title="Profile" history={history} />
            <Formik
                initialValues={{
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    contact: user.contact,
                    title: user.title,
                    company: user.company,
                }}
                validationSchema={ProfileSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    setSubmitting(true);
                    dispatch(updateProfile(values)).then(
                        d => {
                            setSubmitting(false);
                            dispatch({
                                type: UPDATE_USER_SUCCESSFUL,
                                payload: {
                                    email: d.email,
                                    first_name: d.first_name,
                                    last_name: d.last_name,
                                    contact: d.contact,
                                    title: d.title,
                                    company: d.company,
                                },
                            });
                        },
                        err => {
                            setSubmitting(false);
                            setResponseErrors(err, setFieldError);
                        },
                    );
                }}>
                {({ isSubmitting }) => (
                    <Form
                        className="d-flex flex-column pb-3"
                        style={{ minHeight: 'calc(100% - 200px)' }}>
                        <Field
                            name="first_name"
                            label="First Name"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="first_name"
                            component={ErrorMessageContainer}
                        />

                        <Field
                            name="last_name"
                            label="Last Name"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="last_name"
                            component={ErrorMessageContainer}
                        />

                        <Field
                            name="email"
                            label="Email Address"
                            type="email"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="email"
                            component={ErrorMessageContainer}
                        />

                        {/* <Field
                            name="password"
                            label="Password"
                            className="mb-4"
                            component={PasswordInput}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="password"
                            component={ErrorMessageContainer}
                        /> */}

                        <Field
                            name="contact"
                            label="Contact"
                            className="mb-4"
                            component={NumericalInput}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="contact"
                            component={ErrorMessageContainer}
                        />

                        <Field
                            name="title"
                            label="Title"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="title"
                            component={ErrorMessageContainer}
                        />

                        <Field
                            name="company"
                            label="Company"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="company"
                            component={ErrorMessageContainer}
                        />

                        <Button
                            block
                            large
                            type="submit"
                            className="mt-auto"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}>
                            <span>Update Profile</span>
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ProfileScreen;
