import React from 'react';

const Spinner = ({ className }) => (
    <div className={`spinner-border ${className || ''}`} role="status">
        <span className="sr-only">Loading...</span>
    </div>
);

const Loader = ({ className, fullscreen }) =>
    fullscreen ? (
        <div
            className="w-100 d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: '100vh' }}>
            <Spinner className={className} />
        </div>
    ) : (
        <Spinner />
    );
export default Loader;
